import React, { useState, useEffect } from "react";
import Layout from "@components/layouts/Layout";
import Seo from "@components/common/Seo";
import SmallBanner from "@components/common/SmallBanner";
import Title from "@components/common/Title";
import Text from "@components/common/Text";
import Products from "@components/sections/Products";
import dayjs from "dayjs";
import axios from "axios";
import useSWR from "swr";
import ProductsNew from "@components/sections/products2/Products";

const endpoints = {
    products: "/.netlify/functions/products",
};

const Tienda = () => {
    const {
        data: result,
        isLoading,
        isValidating,
    } = useSWR(endpoints.products, axios.get, {
        dedupingInterval: 30 * 1000,
    });
    const [filteredItems, setFilteredItems] = useState([]);

    useEffect(() => {
        if (result?.data?.products) {
            let products = result?.data?.products.filter(
                x => x.enabled && !x.hidden && x?.category?.includes("others"),
            );

            products = products.map(p => {
                const { created_at, updated_at, ...rest } = p;
                return {
                    ...rest,
                    created_at: dayjs(created_at).toDate(),
                    updated_at: dayjs(updated_at).toDate(),
                };
            });

            products = products.sort((a, b) => b.stock - a.stock || b.created_at - a.created_at);

            setFilteredItems(products);
        }
    }, [result]);

    const presenciales = filteredItems?.filter(x => x.category?.includes("face-to-face"));
    const online = filteredItems?.filter(x => x.category?.includes("online"));

    return (
        <>
            <Layout>
                <Seo
                    title="Nuestra tienda: Otros cursos y formaciones"
                    description="Aquí encontrarás una selección diversa de cursos que desafían las convenciones, abarcando temas tan fascinantes como eclécticos. Amplía tus horizontes y sumérgete en una experiencia educativa única y enriquecedora."
                />
                <SmallBanner
                    title="Otros cursos y formaciones"
                    alt="Otros cursos y formaciones"
                    subtitle="Aquí encontrarás una selección diversa de cursos que desafían las convenciones, abarcando temas tan fascinantes como eclécticos."
                    filename="bgs/bgPsicos"
                />

                {/** Contenido */}
                <div className="container lg:px-0 pt-10 pb-20 gap-10 grid grid-cols-1">
                    {/** Cursos presenciales */}
                    <section className="bg-gray-50 border-b-2 border-gray-300 pb-10">
                        <Title>Formación presencial</Title>
                        <Text>
                            Nadie nace con los conocimientos y el dominio necesario para enfrentarse
                            a estas pruebas. Te enseñaremos los trucos para que tú cerebro
                            evolucione para superar con seguridad los exámenes.
                        </Text>
                        <div className="mt-10">
                            {process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true" ? (
                                <ProductsNew
                                    items={presenciales}
                                    loading={isLoading || isValidating}
                                />
                            ) : (
                                <Products items={presenciales} />
                            )}
                        </div>
                    </section>

                    {/** Cursos online */}
                    <section className="bg-gray-50 border-b-2 border-gray-300 pb-10">
                        <Title>Formación online</Title>
                        <Text>Prepárate desde casa o cualquier lugar sin horarios.</Text>
                        <div className="mt-10">
                            {process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true" ? (
                                <ProductsNew items={online} loading={isLoading || isValidating} />
                            ) : (
                                <Products items={online} />
                            )}
                        </div>
                    </section>
                </div>
            </Layout>
        </>
    );
};

export default Tienda;
